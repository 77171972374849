import React from "react";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import AdminFooter from "../components/Footers/AdminFooter";
import { Container } from "reactstrap";
import AdminSidebar from "../components/Sidebar/AdminSidebar";

const AdminLayout = (props) => {
  return (
    <>
      <AdminSidebar
      // checkPermission={checkPermission}
      // {...props}
      // routes={routes}
      // logo={{
      //   innerLink: "/admin/dashboard",
      //   imgSrc: require("../assets/img/brand/argon-react.png").default,
      //   imgAlt: "...",
      // }}
      />
      <div className="main-content">
        <AdminNavbar name={props.name} />
        {props.children}
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default AdminLayout;
