import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  LuLayoutDashboard,
  LuBadgeDollarSign,
  LuUserCircle,
  LuMapPin,
  LuUserPlus,
  LuBuilding,
} from "react-icons/lu";
import { TbAddressBook } from "react-icons/tb";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { useClientType } from "../../ContextApi/ClientTypeContext";
import MessageIcon from "../svg/MessageIcon";

const Sidebar = () => {
  const [openDropdown, setOpenDropdown] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { clientType } = useClientType();

  const handleDropdownToggle = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  const associateRoute = [
    {
      title: "Dashboard",
      icon: <LuLayoutDashboard />,
      path: "/client/dashboard",
    },
    {
      title: "Add Money",
      icon: <LuBadgeDollarSign />,
      path: "/client/add-money",
    },
    {
      title: "Profile Management",
      icon: <LuUserCircle />,
      path: "/client/profile",
    },
    {
      title: "Transaction",
      icon: <TbAddressBook />,
      path: "/client/transaction",
      child: [
        {
          title: "Money Transfer",
          path: "/client/money-transfer",
        },
        // {
        //   title: "Money Withdrawal",
        //   path: "/client/money-withdrawal",
        // },
        {
          title: "Transaction History",
          path: "/client/transaction-history",
        },
      ],
    },
    {
      title: "Map View",
      icon: <LuMapPin />,
      path: "/client/geolocation",
    },
    {
      title: "Message",
      icon: <MessageIcon />,
      path: "/client/message",
    },
  ];

  const agencyRoute = [
    {
      title: "Dashboard",
      icon: <LuLayoutDashboard />,
      path: "/agency/dashboard",
    },
    {
      title: "Add Money",
      icon: <LuBadgeDollarSign />,
      path: "/agency/add-money",
    },
    {
      title: "Transaction",
      icon: <TbAddressBook />,
      path: "/agency/transaction",
      child: [
        {
          title: "Money Transfer",
          path: "/agency/money-transfer",
        },
        {
          title: "Search Transaction",
          path: "/agency/transaction-withdrwal",
        },
        {
          title: "Transaction History",
          path: "/agency/transaction-history",
        },
      ],
    },
    {
      title: "Teller Account",
      icon: <LuBuilding />,
      path: "/agency/teller",
    },
    {
      title: "Profile Management",
      icon: <LuUserCircle />,
      path: "/agency/profile",
    },
    {
      title: "Map View",
      icon: <LuMapPin />,
      path: "/agency/geolocation",
    },
  ];

  const normalRoute = [
    {
      title: "Dashboard",
      icon: <LuLayoutDashboard />,
      path: "/client/dashboard",
    },
    {
      title: "Add Money",
      icon: <LuBadgeDollarSign />,
      path: "/client/add-money",
    },
    {
      title: "Profile Management",
      icon: <LuUserCircle />,
      path: "/client/profile",
    },
    {
      title: "Transaction",
      icon: <TbAddressBook />,
      path: "/client/transaction",
      child: [
        {
          title: "Money Transfer",
          path: "/client/money-transfer",
        },
        {
          title: "Transaction History",
          path: "/client/transaction-history",
        },
      ],
    },
    {
      title: "Map View",
      icon: <LuMapPin />,
      path: "/client/geolocation",
    },
    {
      title: "Invite User",
      icon: <LuUserPlus />,
      path: "/client/invite",
    },
  ];
  const handleRoute = (link) => {
    navigate(link);
  };

  return (
    <>
      <div className="logo d-flex justify-content-center">
        <Link to="/client/dashboard" className="p-0 m-0">
          <img src="../assets/logo-dashboard.webp" alt="logo" />
        </Link>
      </div>

      <div className="dashboard-items pt-4">
        <ul className="m-0 p-0">
          {clientType === "normal" &&
            normalRoute.map((items, i) => {
              const hasChildren = items.child && items.child.length > 0;
              const isActive = location.pathname.includes(items.path);
              const isOpen = openDropdown === i;

              return (
                <li
                  key={i}
                  className={`m-0 p-3 px-3 h-auto ${
                    (isActive && "active") || (isOpen && "active")
                  }`}
                  onClick={() =>
                    hasChildren
                      ? handleDropdownToggle(i)
                      : handleRoute(items.path, null)
                  }
                  role="button"
                >
                  <div className="list-box d-flex justify-content-between align-items-center m-0 p-0">
                    <div className="d-flex align-items-center">
                      {items.icon}
                      <p className="m-0 p-0 pl-3">{items.title}</p>
                    </div>
                    {hasChildren &&
                      (isOpen ? (
                        <MdKeyboardArrowUp className="drop-icon" />
                      ) : (
                        <MdKeyboardArrowDown className="drop-icon" />
                      ))}
                  </div>

                  {/* Render child dropdown items below the parent */}
                  {hasChildren && isOpen && (
                    <ul className="sub-menu p-0">
                      {items.child.map((childItem, j) => (
                        <li
                          key={j}
                          // onClick={() => handleRoute(childItem.path)}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRoute(childItem.path);
                          }}
                          className="d-flex m-0 px-0 d-flex justify-content-between align-items-center px-3"
                        >
                          <p className="m-0 p-0 pl-3">{childItem.title}</p>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              );
            })}
          {clientType === "associate" &&
            associateRoute.map((items, i) => {
              const hasChildren = items.child && items.child.length > 0;
              const isActive = location.pathname.includes(items.path);
              const isOpen = openDropdown === i;

              return (
                <li
                  key={i}
                  className={`m-0 p-3 px-3 h-auto ${
                    (isActive && "active") || (isOpen && "active")
                  }`}
                  onClick={() =>
                    hasChildren ? handleDropdownToggle(i) : navigate(items.path)
                  }
                  role="button"
                >
                  <div className="list-box d-flex justify-content-between align-items-center m-0 p-0">
                    <div className="d-flex align-items-center">
                      {items.icon}
                      <p className="m-0 p-0 pl-3">{items.title}</p>
                    </div>
                    {hasChildren &&
                      (isOpen ? (
                        <MdKeyboardArrowUp className="drop-icon" />
                      ) : (
                        <MdKeyboardArrowDown className="drop-icon" />
                      ))}
                  </div>

                  {/* Render child dropdown items below the parent */}
                  {hasChildren && isOpen && (
                    <ul className="sub-menu p-0">
                      {items.child.map((childItem, j) => {
                        const isActiveChild = location.pathname.includes(
                          childItem.path
                        );
                        return (
                          <li
                            key={j}
                            onClick={() => handleRoute(childItem.path)}
                            className={`d-flex m-0 px-0 d-flex  ${
                              isActiveChild && "active"
                            } justify-content-between align-items-center px-3`}
                          >
                            <p className="m-0 p-0 pl-3">{childItem.title}</p>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
              );
            })}
          {clientType === "agency" &&
            agencyRoute.map((items, i) => {
              const hasChildren = items.child && items.child.length > 0;
              const isActive = location.pathname.includes(items.path);
              const isOpen = openDropdown === i;

              return (
                <li
                  key={i}
                  className={`m-0 p-3 px-3 h-auto ${
                    (isActive && "active") || (isOpen && "active")
                  }`}
                  onClick={() =>
                    hasChildren ? handleDropdownToggle(i) : navigate(items.path)
                  }
                  role="button"
                >
                  <div className="list-box d-flex justify-content-between align-items-center m-0 p-0">
                    <div className="d-flex align-items-center">
                      {items.icon}
                      <p className="m-0 p-0 pl-3">{items.title}</p>
                    </div>
                    {hasChildren &&
                      (isOpen ? (
                        <MdKeyboardArrowUp className="drop-icon" />
                      ) : (
                        <MdKeyboardArrowDown className="drop-icon" />
                      ))}
                  </div>

                  {/* Render child dropdown items below the parent */}
                  {hasChildren && isOpen && (
                    <ul className="sub-menu p-0">
                      {items.child.map((childItem, j) => (
                        <li
                          key={j}
                          onClick={() => handleRoute(childItem.path)}
                          className="d-flex m-0 px-0 d-flex justify-content-between align-items-center px-3"
                        >
                          <p className="m-0 p-0 pl-3">{childItem.title}</p>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              );
            })}
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
