/* eslint-disable import/no-anonymous-default-export */

import {
  CLIENT_SEND_MONEY_AUTH_CLIENT,
  CLIENT_SEND_MONEY_CLIENT,
  CLIENT_TRANSACTION_LIST,
  CLIENT_TRASNFER_TRANSACTION_LIST,
  CLIENT_SEND_MONEY_UNREGISTER_TO_UNREGISTER,
  CLIENT_SEND_MONEY_REGISTER_TO_UNREGISTER,
  CLIENT_SEND_MONEY_REGISTER_TO_REGISTER_ONLINE,
  CLIENT_SEND_MONEY_REGISTER_TO_REGISTER_OFFLINE,
  AGENCY_TRASNFER_TRANSACTION_LIST,
  CLIENT_TRASNFER_TRANSACTION_LIST_BYID,
} from "../../types/index";

const initialState = {
  transaction: {},
  transactionAll: { data: [] },
  transactionById: {},
  sendMoneyAuthClient: {},
  sendMoneyUnAuthClient: {},
  sendMoneyUnregisterToUnregister: {},
  sendMoneyRegisterToUnregister: {},
  sendMoneyRegisterToRegisterOnline: {},
  sendMoneyRegisterToRegisterOffline: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CLIENT_TRANSACTION_LIST:
      return {
        ...state,
        transaction: payload,
      };
    case CLIENT_TRASNFER_TRANSACTION_LIST:
      return {
        ...state,
        transactionAll: payload,
      };
    case CLIENT_TRASNFER_TRANSACTION_LIST_BYID:
      return {
        ...state,
        transactionById: payload,
      };
    case CLIENT_SEND_MONEY_AUTH_CLIENT:
      return {
        ...state,
        sendMoneyAuthClient: payload,
      };
    case CLIENT_SEND_MONEY_CLIENT:
      return {
        ...state,
        sendMoneyUnAuthClient: payload,
      };
    case CLIENT_SEND_MONEY_UNREGISTER_TO_UNREGISTER:
      return {
        // ...state,
        sendMoneyUnregisterToUnregister: payload,
      };
    case CLIENT_SEND_MONEY_REGISTER_TO_UNREGISTER:
      return {
        // ...state,
        sendMoneyRegisterToUnregister: payload,
      };
    case CLIENT_SEND_MONEY_REGISTER_TO_REGISTER_ONLINE:
      return {
        // ...state,
        sendMoneyRegisterToRegisterOnline: payload,
      };
    case CLIENT_SEND_MONEY_REGISTER_TO_REGISTER_OFFLINE:
      return {
        // ...state,
        sendMoneyRegisterToRegisterOffline: payload,
      };
    case AGENCY_TRASNFER_TRANSACTION_LIST:
      return {
        transactionAll: payload,
      };
    default:
      return state;
  }
}
