/* eslint-disable import/no-anonymous-default-export */

import { CHAT_USERS_LIST } from "../../types/index";

const initialState = { chatusersList: {} };
export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case CHAT_USERS_LIST:
            return {
                ...state,
                chatusersList: payload,
            };

        default:
            return state;
    }
}